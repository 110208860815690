<template>
    <div class="wx_loginAll">
        <van-skeleton title avatar :avatar-size="48" :row="9" round  />
    </div>
</template>

<style lang="less" scoped>

</style>

<script>
import { wx_login, ins_login } from '@/api/zh/login'
import { getAccountInfo } from "@/api/zh/mine";
export default {
    methods: {
        
        wxCallback(code){
            let params = { code: code }
            wx_login(params).then( res => {
                if(res) {
                    localStorage.setItem('token', res.access_token)
                    localStorage.setItem('token2', res.access_token)
                    // this.$router.push('/zh/account')
                    getAccountInfo().then(res => {  
                        if(res){
                            localStorage.setItem('setPersonal',JSON.stringify(res.data))
                            let path = sessionStorage.getItem('preLink')
                            this.$router.push(path)

                        }
                    })
                }
            })
        },

        insCallback(code){
            let params = {  code:code }
            ins_login(params).then(res => {
                if(res) {
                    localStorage.setItem('token', res.access_token)
                    localStorage.setItem('token2', res.access_token)
                    // this.$router.push('/zh/account')
                    getAccountInfo().then(res => {  
                        if(res){
                            localStorage.setItem('setPersonal',JSON.stringify(res.data))
                            let path = sessionStorage.getItem('preLink')
                            this.$router.push(path)

                        }
                    })
                }
            });
        },
    },
    mounted() {
        if(this.$route.query.state == 'wx') {
            this.wxCallback(this.$route.query.code)   
        }else {
            this.insCallback(this.$route.query.code)   
        }
        
    },
}
</script>

<style lang="less" scoped>
.wx_loginAll {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #fff;
    padding:  20vh 16px 0;
}
</style>